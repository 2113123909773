
<template>
	<div>
		{{uploadUrl}}
		<br>
		{{ossParam}}
		<el-upload
			class="avatar-uploader"
			:action="uploadUrl"
			:data='ossParam'
			list-type="picture-card"
			:on-success="handleAvatarSuccess"
			:before-upload="beforeAvatarUpload"
		>
			<i class="el-icon-plus"></i>
		</el-upload>
	</div>

</template>
<script>
	import {BasicApi} from '@/api';
	export default {
		data() {
			return {
				imageUrl: '',
				ossParam: {},
				ossSign: {},
				uploadUrl: ''
			};
		},
		methods: {
			fnUpdateRequest() {

			},
			handleAvatarSuccess(res, file) {
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			randomString(len) {
				len = len || 32;
				let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
				let maxPos = chars.length;
				let pwd = '';
				for (let i = 0; i < len; i++) {
					pwd += chars.charAt(Math.floor(Math.random() * maxPos));
				}
				return pwd;
			},
			async beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				this.ossSign = (await BasicApi.aliyunOssSign()).data || {};
				this.uploadUrl = this.ossSign.host;
				let name = `${this.randomString(15)}.${file.name.split(".").pop()}`;
				this.ossParam = {
					name: file.name,
					key: `${this.ossSign.dir}${name}`,
					policy: this.ossSign.policy,
					OSSAccessKeyId: this.ossSign.accessId,
					callback: this.ossSign.callback,
					signature: this.ossSign.signature,
				};
				return isJPG && isLt2M;
			}
		}
	}
</script>
<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}
	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
